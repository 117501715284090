var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reset-password-page"
  }, [_c('auth-layout', [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "auth-form",
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onResetPassword);
            }
          }
        }, [_c('p', [_vm._v("تعيين كلمة المرور الجديدة")]), _c('ValidationProvider', {
          attrs: {
            "name": "كلمة المرور القديمة التي وصلتك عبر SMS",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors,
                classes = _ref2.classes;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.authForm.oldPassword,
                  expression: "authForm.oldPassword"
                }],
                class: classes,
                attrs: {
                  "type": "password",
                  "placeholder": "أدخل كلمة المرور التي وصلتك عبر SMS"
                },
                domProps: {
                  "value": _vm.authForm.oldPassword
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.$set(_vm.authForm, "oldPassword", $event.target.value);
                  }
                }
              }), _c('span', [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "كلمة المرور الجديدة",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors,
                classes = _ref3.classes;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.authForm.newPassword,
                  expression: "authForm.newPassword"
                }],
                class: classes,
                attrs: {
                  "type": "password",
                  "placeholder": "أدخل كلمة المرور الجديدة"
                },
                domProps: {
                  "value": _vm.authForm.newPassword
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.$set(_vm.authForm, "newPassword", $event.target.value);
                  }
                }
              }), _c('span', [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }), _c('zadi-button', {
          attrs: {
            "loader": _vm.resetPasswordLoading,
            "title": "تأكيد"
          }
        })], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }