<template>
  <div class="zadi-auth-layout">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <slot />
      </div>
      <div class="col-lg-7 col-md-6">
        <div class="image">
          <img src="../../../../public/assets/images/website/zadi/hero/hero-main.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style scoped>
.zadi-auth-layout {
  padding: 40px;
}

.col-lg-7.col-md-6 .image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5FBFB;
  border-radius: 20px;
  padding: 40px 20px;
}

.col-lg-7.col-md-6 .image img {
  max-width: 450px;
  width: 100%;
}

@media (max-width: 767px) {
  .zadi-auth-layout {
    padding: 20px 20px 60px 20px;
  }

  .row {
    flex-direction: column-reverse;
  }

  .col-lg-7.col-md-6 .image {
    margin-bottom: 50px;
  }
}
</style>