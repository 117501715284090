<template>
  <div class="reset-password-page">
    <auth-layout>
      <!-- <div class="auth-form">
        <p>تعيين كلمة المرور الجديدة</p>
        <input type="password" v-model="authForm.oldPassword" placeholder="أدخل كلمة المرور الجديدة">
        <input type="password" v-model="authForm.newPassword" placeholder="أدخل تأكيد كلمة المرور">
        <zadi-button @on-click="onResetPassword()" :loader="resetPasswordLoading" title="تأكيد" />
      </div> -->
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="auth-form" @submit.prevent="handleSubmit(onResetPassword)">
          <p>تعيين كلمة المرور الجديدة</p>
          <ValidationProvider name="كلمة المرور القديمة التي وصلتك عبر SMS" rules="required|min:6" v-slot="{ errors, classes }">
            <input :class="classes" type="password" v-model="authForm.oldPassword" placeholder="أدخل كلمة المرور التي وصلتك عبر SMS">
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="كلمة المرور الجديدة" rules="required|min:6" v-slot="{ errors, classes }">
            <input :class="classes" type="password" v-model="authForm.newPassword" placeholder="أدخل كلمة المرور الجديدة">
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <zadi-button :loader="resetPasswordLoading" title="تأكيد" />
        </form>
      </ValidationObserver>
    </auth-layout>
  </div>
</template>

<script>
import ZadiButton from '../../components/shared/ZadiButton'
import AuthLayout from '../../layout/AuthLayout'
import auth from '../../helpers/auth.js';

export default {
  metaInfo: {
    title: "مشروع زادي | تعيين كلمة مرور جديدة",
  },
  components: { ZadiButton, AuthLayout },
  data() {
    return {
      authForm: {
        oldPassword: null,
        newPassword: null,
      },
    }
  },
  computed: {
    resetPasswordLoading() {
      return auth.isLoading;
    }
  },
  methods: {
    onResetPassword() {
      auth.resetPassword(this.authForm)
    }
  },
};
</script>

<style scoped>
.auth-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.auth-form p {
  color: #9A9A9A;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
}


.auth-form>span {
  display: flex;
  flex-direction: column;
}

.auth-form>span span {
  color: #dc3545;
  font-size: 15px;
  padding: 5px 0px 0px 0px;
}

.auth-form input {
  padding: 10px;
  border: 1px solid #C1C1C1;
  appearance: none;
  outline: none;
  border-radius: 6px;
  color: #515667;
}

.auth-form input.invalid {
  border: 1px solid #dc3545;
}
</style>
