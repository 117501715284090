<template>
  <button @click="handleClick()" :disabled="loader || disabled" :class="variant">
    <template v-if="!loader">
      {{ title }}
    </template>
    <template v-else>
      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <path fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
            to="360 50 50" repeatCount="indefinite" />
        </path>
      </svg>
    </template>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
    },
    to: {
      type: Object,
      default: () => null,
    },
    loader: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    variant: {
      type: String,
      default: () => 'primary',
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleClick() {
      if (this.to) {
        this.$router.push(this.to);
        return
      }
      this.$emit('on-click')
    }
  }
};
</script>

<style scoped>
button {
  color: white;
  width: 100%;
  height: 50px;
  border-radius: 35px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

button.primary {
  background: #9DD8D4;
}

button.secondary {
  background: #f4acbf;
}

button svg {
  height: 100%;
}

button.primary:hover {
  background: #6ed6cf;
  transform: scale(1.02);
}

button.secondary:hover {
  background: #f58da9;
  transform: scale(1.02);
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  transform: unset !important;
}
</style>
